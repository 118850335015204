<template>
  <div class="main">
    <!-- 第1页 -->
    <div key="card1" class="card1" v-if="currentPage == 1">
      <div class="centerTxt">
        <anime-txt
          id="news2"
          ref="news2"
          class="anime_txt"
          :txt="tipsWords"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="typeIn"
          outEffect="fadeOut"
        />
      </div>
    </div>

    <!-- 第2页 -->
    <div key="card2" class="card2" v-if="currentPage == 2">
      <div class="redWords">
        <anime-txt
          id="redTxt"
          ref="redTxt"
          class="anime_txt"
          txt="财富优选一年持有FOF"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="slow"
          inEffect="topIn"
          outEffect="fadeOut"
        />
      </div>
    </div>

    <!-- 第3页 -->
    <div key="card3" class="card3" v-if="currentPage == 3">
      <div class="toptittle">
        中信证券财富优选一年持有期混合型基金中基金(FOF)集合资产管理计划A类
      </div>
      <div class="desc">
        <span> 900012 </span>
        <span> 中风险 </span>
        <span> ｜ </span>
        <span> FOF_偏股 </span>
        <span> ｜ </span>
        <span class="red"> 锁定期1年 </span>
      </div>
      <ul class="column column1">
        <li v-for="(item, index) in list1" :key="index">
          <div :class="`left ${item.value >= 0 ? '' : 'green'}`">
            <countTo
              :startVal="0"
              :endVal="item.value"
              :decimals="2"
              :duration="2000"
            ></countTo>
            {{ item.unit }}
          </div>
          <div class="right">{{ item.label }}</div>
        </li>
      </ul>
      <div class="dashLine"></div>
      <ul class="column column2">
        <li v-for="(item, index) in list2" :key="index">
          <div class="left">
            <template v-if="item.count">
              <countTo
                :startVal="0"
                :endVal="item.value"
                :decimals="2"
                :duration="2000"
              ></countTo>
            </template>
            <template v-else>
              {{ item.value }}
            </template>
            {{ item.unit }}
          </div>
          <div class="right">{{ item.label }}</div>
        </li>
      </ul>
    </div>
    <!-- 第4页 -->
    <div key="card4" class="card4" v-if="currentPage == 4">
      <div class="toptittle">
        中信证券财富优选一年持有（原中信证券基金精选）
      </div>
      <div class="chartWrap">
        <div class="chart" ref="mixChart"></div>
      </div>
    </div>
    <!-- 第5页 -->
    <div key="card5" class="card5" v-if="currentPage == 5">
      <div class="toptittle">基金抗跌能力</div>
      <div class="bubble bubble1">
        <div class="inner">
          <div class="tittle">
            最大撤回 <br />
            <span class="countWrap" style="width: 150px">
              <countTo
                :startVal="0"
                :endVal="loopNum1"
                :decimals="2"
                :duration="2000"
              ></countTo>
            </span>
            %
          </div>
          <div class="desc">
            指标均值
            <span class="countWrap" style="width: 60px">
              <countTo
                :startVal="0"
                :endVal="loopNum2"
                :decimals="2"
                :duration="2000"
              ></countTo>
            </span>
            %
          </div>
        </div>
      </div>
      <div class="bubble bubble2">
        <div class="inner">
          <div class="tittle">NO.16</div>
          <div class="desc desc2">
            共
            <span class="countWrap" style="width: 70px">
              <countTo
                :startVal="0"
                :endVal="loopNum3"
                :decimals="0"
                :duration="2000"
              ></countTo>
            </span>
            支同类基金
          </div>
        </div>
      </div>
    </div>
    <!-- 第6页 -->
    <div key="card6" class="card6" v-if="currentPage == 6">
      <div class="toptittle">持仓（投资分布）</div>
      <div class="dateTime">2021-09-30</div>
      <div class="pieWrap">
        <donut-chart
          ref="refDonutChart1"
          id="refDonutChart1"
          customClass="c_donut1"
        >
        </donut-chart>
        <div class="circleMiddle"> 
            <p>净资产</p>
            <countTo
              class="count"
              :startVal="0"
              :endVal="loopNum4"
              :decimals="2"
              :duration="2000"
            ></countTo>
            亿元
        </div>
      </div>
      <ul class="column column1">
        <li v-for="(item, index) in listTemp" :key="index">
          <span>
            <i class="circle" :style="`background:${pieColorList[index]};`"></i>
            {{ item.name }}
          </span>
          <span>
            <countTo
              :startVal="0"
              :endVal="item.value"
              :decimals="2"
              :duration="2000"
            ></countTo>
            %
          </span>
        </li>
      </ul>
    </div>
    <!-- 第7页 -->
    <div key="card7" class="card7" v-if="currentPage == 7">
      <div class="toptittle">基金持仓前十持仓占比（%）</div>
      <div class="smallTittle">持仓截止日期：2021-12-31</div>
      <!-- <div class="chart" ref="chart1"></div> -->
      <bar-chart-horizen ref="chart1" id="chart1" class="chart c_bar3"> </bar-chart-horizen>
      <div class="dashLine"></div>
      <div class="rightNum">合计：42.37%</div>
    </div>
    <!-- 第8页 -->
    <div key="card8" class="card8" v-if="currentPage == 8">
      <div class="toptittle">基金持仓前十持仓份额（万份）</div>
      <div class="smallTittle">持仓截止日期：2021-12-31</div>
      <!-- <div class="chart" ref="chart2"></div> -->
         <bar-chart-horizen ref="chart2" id="chart2" class="chart c_bar3"> </bar-chart-horizen>
      <div class="dashLine"></div>
      <div class="rightNum">合计：18351.46万份</div>
    </div>
    <!-- 第9页 -->
    <div key="card9" class="card9" v-if="currentPage == 9">
      <div class="toptittle">行业配置前十（%）</div>
      <div class="smallTittle">截止日期：2021-12-31</div>
      <!-- <div class="chart" ref="chart3"></div> -->
         <bar-chart-horizen ref="chart3" id="chart3" class="chart c_bar3"> </bar-chart-horizen>
      <div class="dashLine"></div>
      <div class="bottomWords">{{chartData.market3[0].label}}占净值比为{{chartData.market3[0].value}}%，排名第一</div>
    </div>
     <svg width="300" height="300" class="svg_gradient">
      <defs>
        <linearGradient id="barPositive" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stop-color="#FF6A58" />
          <stop offset="100%" stop-color="#E13131" />
        </linearGradient>
        <linearGradient id="barNegative" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stop-color="#136949" />
          <stop offset="100%" stop-color="#13AE88" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import _ from "lodash";
import animeTxt from "@/components/animeTxt/index.vue";
import { sleep } from "@/utils/common";
import donutChart from "@/components/donutChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index.vue";
export default {
  components: {
    donutChart,
    barChartHorizen,
    countTo,
    animeTxt,
  },
  data: function () {
    return {
      myChart: null,
      currentPage: 1,
      tipsWords:
        "本视频内容涉及理财产品，风险等级为R3（中风险），适合C3及以上投资者，基金投资有风险，入市需谨慎。",
      list1: [],
      list2: [],
      temList1: [
        {
          value: 254.85,
          label: "成立以来涨幅",
          unit: "%",
        },
        {
          value: 0.51,
          label: "日涨跌幅",
          unit: "%",
        },
        {
          value: 1.4523,
          label: "单位净值(12-22)",
        },
      ],
      temList2: [
        {
          value: 7.22,
          label: "资产规模",
          unit: "亿元",
          count: true,
        },
        {
          value: "2021-07-22",
          label: "成立日期",
        },
        {
          value: "中信证券股份有限公司",
          label: "基金管理人",
        },
        {
          value: "中信银行股份有限公司",
          label: "基金托管人",
        },
      ],
      loopNum1: undefined,
      loopNum2: undefined,
      loopNum3: undefined,
      loopNum4: undefined,
      listTemp: [],
      population2: [
        { name: "基金", value: 89.75 },
        { name: "债券", value: 5.14 },
        { name: "现金", value: 4.93 },
        { name: "其他", value: 0.18 },
      ],
      pieColorList: ["#F7B500", "#348ED2", "#FF6A58", "#FA6400"],
      chartData:{
         market1: [
       {label:"国富估值优势",value:4.71},
    {label:"海富通改革",value:4.7},
    {label:"中欧量化",value:4.67},
    {label:"南方转型增长A",value:4.67},
    {label:"易方达新经济",value:4.67},
    {label:"博道远航A",value:4.62},
    {label:"银华心怡A",value:4.56},
    {label:"富国新机遇A",value:3.72},
    {label:"泰康沪深300ETF",value:3.2},
    {label:"兴全合宜A",value:2.85}
      ],
       market2: [
     {label:"国富估值优势",value:1923.5},
        {label:"海富通改革",value:1665.67},
        {label:"中欧量化",value:2630.64},
        {label:"南方转型增长A",value:2225.95},
        {label:"易方达新经济",value:1109.07},
        {label:"博道远航A",value:3140.68},
        {label:"银华心怡A",value:1426.16},
        {label:"富国新机遇A",value:2006.87},
        {label:"泰康沪深300ETF",value:666.62},
        {label:"兴全合宜A",value:1556.3} 
      ],
       market3: [
        {label:"金融业",value:5.14},
        {label:"制造业",value:3.05},
        {label:"文体娱乐业",value:1.09},
        {label:"信息软件业",value:1.07},
        {label:"租赁和商务服务业",value:0.75},
        {label:"水利环境公共设施",value:0.58},
        {label:"采矿业",value:0.28},
        {label:"批发和零售业",value:0.0031},
        {label:"科学研究和技术",value:0.0026},
        {label:"建筑业",value:0.0008},
        {label:"住宿和餐饮业",value:0.0003}
      ]
      },
      mixChartData:[
    {"日期":"2011/12/31","中信证券财富优选一年持有":"0.10","业绩基准":"-0.27","中证800指数":"-0.10"},
    {"日期":"2012/1/31","中信证券财富优选一年持有":"0.43","业绩基准":"-0.33","中证800指数":"3.93"},
    {"日期":"2012/2/29","中信证券财富优选一年持有":"0.73","业绩基准":"3.45","中证800指数":"12.39"},
    {"日期":"2012/3/29","中信证券财富优选一年持有":"0.27","业绩基准":"0.85","中证800指数":"4.23"},
    {"日期":"2012/3/31","中信证券财富优选一年持有":"0.41","业绩基准":"0.98","中证800指数":"4.54"},
    {"日期":"2012/4/30","中信证券财富优选一年持有":"2.00","业绩基准":"3.84","中证800指数":"11.90"},
    {"日期":"2012/5/31","中信证券财富优选一年持有":"2.83","业绩基准":"5.25","中证800指数":"12.76"},
    {"日期":"2012/6/30","中信证券财富优选一年持有":"1.49","业绩基准":"3.69","中证800指数":"5.16"},
    {"日期":"2012/7/31","中信证券财富优选一年持有":"-0.31","业绩基准":"1.44","中证800指数":"-1.30"},
    {"日期":"2012/8/31","中信证券财富优选一年持有":"-0.34","业绩基准":"-0.07","中证800指数":"-5.58"},
    {"日期":"2012/9/30","中信证券财富优选一年持有":"0.25","业绩基准":"1.39","中证800指数":"-2.29"},
    {"日期":"2012/10/31","中信证券财富优选一年持有":"0.80","业绩基准":"1.18","中证800指数":"-3.76"},
    {"日期":"2012/11/30","中信证券财富优选一年持有":"0.99","业绩基准":"-2.16","中证800指数":"-10.07"},
    {"日期":"2012/12/31","中信证券财富优选一年持有":"1.95","业绩基准":"4.72","中证800指数":"5.71"},
    {"日期":"2013/8/31","中信证券财富优选一年持有":"0.99","业绩基准":"10.36","中证800指数":"1.74"},
    {"日期":"2013/9/26","中信证券财富优选一年持有":"3.11","业绩基准":"12.50","中证800指数":"5.07"},
    {"日期":"2013/9/27","中信证券财富优选一年持有":"3.32","业绩基准":"12.80","中证800指数":"5.52"},
    {"日期":"2013/9/30","中信证券财富优选一年持有":"4.08","业绩基准":"13.36","中证800指数":"6.34"},
    {"日期":"2013/10/8","中信证券财富优选一年持有":"5.09","业绩基准":"14.09","中证800指数":"7.88"},
    {"日期":"2013/10/9","中信证券财富优选一年持有":"5.19","业绩基准":"14.49","中证800指数":"8.57"},
    {"日期":"2013/10/10","中信证券财富优选一年持有":"5.01","业绩基准":"14.04","中证800指数":"7.63"},
    {"日期":"2013/11/28","中信证券财富优选一年持有":"2.66","业绩基准":"12.73","中证800指数":"7.69"},
    {"日期":"2013/12/20","中信证券财富优选一年持有":"1.01","业绩基准":"9.95","中证800指数":"1.17"},
    {"日期":"2013/12/23","中信证券财富优选一年持有":"0.93","业绩基准":"10.12","中证800指数":"1.35"},
    {"日期":"2013/12/24","中信证券财富优选一年持有":"1.28","业绩基准":"10.43","中证800指数":"1.65"},
    {"日期":"2013/12/25","中信证券财富优选一年持有":"1.77","业绩基准":"11.00","中证800指数":"2.48"},
    {"日期":"2013/12/26","中信证券财富优选一年持有":"1.25","业绩基准":"10.31","中证800指数":"0.72"},
    {"日期":"2013/12/27","中信证券财富优选一年持有":"1.80","业绩基准":"11.06","中证800指数":"2.38"},
    {"日期":"2013/12/30","中信证券财富优选一年持有":"1.66","业绩基准":"11.13","中证800指数":"2.33"},
    {"日期":"2013/12/31","中信证券财富优选一年持有":"2.23","业绩基准":"11.46","中证800指数":"3.45"},
    {"日期":"2014/11/18","中信证券财富优选一年持有":"17.39","业绩基准":"21.73","中证800指数":"18.34"},
    {"日期":"2014/11/19","中信证券财富优选一年持有":"17.73","业绩基准":"21.93","中证800指数":"18.32"},
    {"日期":"2014/11/20","中信证券财富优选一年持有":"17.84","业绩基准":"21.90","中证800指数":"18.24"},
    {"日期":"2014/11/21","中信证券财富优选一年持有":"20.00","业绩基准":"22.60","中证800指数":"20.15"},
    {"日期":"2014/11/22","中信证券财富优选一年持有":"20.00","业绩基准":"22.60","中证800指数":"20.15"},
    {"日期":"2014/11/23","中信证券财富优选一年持有":"20.00","业绩基准":"22.60","中证800指数":"20.15"},
    {"日期":"2015/1/8","中信证券财富优选一年持有":"64.00","业绩基准":"31.78","中证800指数":"55.09"},
    {"日期":"2015/1/9","中信证券财富优选一年持有":"64.42","业绩基准":"31.72","中证800指数":"54.43"},
    {"日期":"2015/1/10","中信证券财富优选一年持有":"64.42","业绩基准":"31.72","中证800指数":"54.43"},
    {"日期":"2015/1/11","中信证券财富优选一年持有":"64.41","业绩基准":"31.72","中证800指数":"54.43"},
    {"日期":"2015/1/12","中信证券财富优选一年持有":"62.50","业绩基准":"31.40","中证800指数":"52.80"},
    {"日期":"2015/1/13","中信证券财富优选一年持有":"63.21","业绩基准":"31.96","中证800指数":"53.33"},
    {"日期":"2016/3/30","中信证券财富优选一年持有":"70.30","业绩基准":"49.77","中证800指数":"48.91"},
    {"日期":"2016/3/31","中信证券财富优选一年持有":"70.44","业绩基准":"49.93","中证800指数":"49.10"},
    {"日期":"2019/2/23","中信证券财富优选一年持有":"86.50","业绩基准":"57.96","中证800指数":"48.76"},
    {"日期":"2019/2/24","中信证券财富优选一年持有":"86.50","业绩基准":"57.96","中证800指数":"48.76"},
    {"日期":"2019/3/16","中信证券财富优选一年持有":"96.12","业绩基准":"61.52","中证800指数":"60.32"},
    {"日期":"2019/3/17","中信证券财富优选一年持有":"96.11","业绩基准":"61.53","中证800指数":"60.32"},
    {"日期":"2019/3/18","中信证券财富优选一年持有":"101.10","业绩基准":"62.95","中证800指数":"64.81"},
    {"日期":"2019/3/19","中信证券财富优选一年持有":"100.91","业绩基准":"62.85","中证800指数":"64.40"},
    {"日期":"2019/3/20","中信证券财富优选一年持有":"100.03","业绩基准":"62.68","中证800指数":"64.40"},
    {"日期":"2020/6/15","中信证券财富优选一年持有":"150.49","业绩基准":"77.89","中证800指数":"68.91"},
    {"日期":"2020/6/16","中信证券财富优选一年持有":"154.28","业绩基准":"78.84","中证800指数":"71.61"},
    {"日期":"2020/6/17","中信证券财富优选一年持有":"155.34","业绩基准":"78.91","中证800指数":"72.01"},
    {"日期":"2020/6/18","中信证券财富优选一年持有":"156.83","业绩基准":"79.13","中证800指数":"72.86"},
    {"日期":"2020/6/19","中信证券财富优选一年持有":"159.96","业绩基准":"79.98","中证800指数":"75.00"},
    {"日期":"2020/6/20","中信证券财富优选一年持有":"159.95","业绩基准":"79.98","中证800指数":"75.00"},
    {"日期":"2020/6/21","中信证券财富优选一年持有":"159.94","业绩基准":"79.99","中证800指数":"75.00"},
    {"日期":"2020/6/22","中信证券财富优选一年持有":"160.68","业绩基准":"80.13","中证800指数":"75.08"},
    {"日期":"2020/6/23","中信证券财富优选一年持有":"163.39","业绩基准":"80.69","中证800指数":"75.87"},
    {"日期":"2020/6/24","中信证券财富优选一年持有":"164.79","业绩基准":"80.90","中证800指数":"76.30"},
    {"日期":"2020/6/25","中信证券财富优选一年持有":"164.78","业绩基准":"80.90","中证800指数":"76.30"},
    {"日期":"2020/8/1","中信证券财富优选一年持有":"200.94","业绩基准":"89.66","中证800指数":"100.08"},
    {"日期":"2020/9/5","中信证券财富优选一年持有":"207.69","业绩基准":"90.32","中证800指数":"102.79"},
    {"日期":"2020/9/6","中信证券财富优选一年持有":"207.68","业绩基准":"90.32","中证800指数":"102.79"},
    {"日期":"2020/9/7","中信证券财富优选一年持有":"200.57","业绩基准":"88.65","中证800指数":"98.50"},
    {"日期":"2020/9/8","中信证券财富优选一年持有":"199.23","业绩基准":"88.60","中证800指数":"99.65"},
    {"日期":"2020/9/9","中信证券财富优选一年持有":"191.55","业绩基准":"86.93","中证800指数":"94.81"},
    {"日期":"2020/9/10","中信证券财富优选一年持有":"191.48","业绩基准":"86.81","中证800指数":"93.86"},
    {"日期":"2020/9/11","中信证券财富优选一年持有":"195.89","业绩基准":"87.76","中证800指数":"95.89"},
    {"日期":"2020/9/12","中信证券财富优选一年持有":"195.87","业绩基准":"87.76","中证800指数":"95.89"},
    {"日期":"2020/9/13","中信证券财富优选一年持有":"195.86","业绩基准":"87.76","中证800指数":"95.89"},
    {"日期":"2020/9/14","中信证券财富优选一年持有":"198.08","业绩基准":"88.17","中证800指数":"96.95"},
    {"日期":"2020/10/11","中信证券财富优选一年持有":"203.02","业绩基准":"89.69","中证800指数":"97.96"},
    {"日期":"2020/10/12","中信证券财富优选一年持有":"210.30","业绩基准":"91.41","中证800指数":"103.90"},
    {"日期":"2020/10/13","中信证券财富优选一年持有":"212.57","业绩基准":"91.78","中证800指数":"104.49"},
    {"日期":"2020/10/14","中信证券财富优选一年持有":"211.82","业绩基准":"91.44","中证800指数":"103.13"},
    {"日期":"2020/10/22","中信证券财富优选一年持有":"205.40","业绩基准":"90.14","中证800指数":"100.76"},
    {"日期":"2020/10/23","中信证券财富优选一年持有":"200.46","业绩基准":"88.96","中证800指数":"98.07"},
    {"日期":"2020/10/24","中信证券财富优选一年持有":"200.45","业绩基准":"88.96","中证800指数":"98.07"},
    {"日期":"2020/10/25","中信证券财富优选一年持有":"200.43","业绩基准":"88.96","中证800指数":"98.07"},
    {"日期":"2020/10/26","中信证券财富优选一年持有":"201.11","业绩基准":"89.13","中证800指数":"97.20"},
    {"日期":"2020/10/27","中信证券财富优选一年持有":"203.78","业绩基准":"89.65","中证800指数":"97.51"},
    {"日期":"2020/10/28","中信证券财富优选一年持有":"207.57","业绩基准":"90.30","中证800指数":"98.91"},
    {"日期":"2020/10/29","中信证券财富优选一年持有":"210.72","业绩基准":"90.82","中证800指数":"100.13"},
    {"日期":"2020/10/30","中信证券财富优选一年持有":"206.53","业绩基准":"89.89","中证800指数":"96.56"},
    {"日期":"2020/10/31","中信证券财富优选一年持有":"206.52","业绩基准":"89.90","中证800指数":"96.56"},
    {"日期":"2021/8/4","中信证券财富优选一年持有":"265.35","业绩基准":"101.37","中证800指数":"111.62"},
    {"日期":"2021/8/5","中信证券财富优选一年持有":"263.79","业绩基准":"100.55","中证800指数":"110.50"},
    {"日期":"2021/8/6","中信证券财富优选一年持有":"262.78","业绩基准":"100.02","中证800指数":"109.81"},
    {"日期":"2021/8/7","中信证券财富优选一年持有":"262.77","业绩基准":"100.02","中证800指数":"109.81"},
    {"日期":"2021/8/8","中信证券财富优选一年持有":"262.76","业绩基准":"100.02","中证800指数":"109.81"},
    {"日期":"2021/8/9","中信证券财富优选一年持有":"263.43","业绩基准":"101.79","中证800指数":"112.19"},
    {"日期":"2021/8/10","中信证券财富优选一年持有":"265.38","业绩基准":"103.43","中证800指数":"114.34"},
    {"日期":"2021/8/11","中信证券财富优选一年持有":"266.83","业绩基准":"103.03","中证800指数":"113.83"},
    {"日期":"2021/8/12","中信证券财富优选一年持有":"266.34","业绩基准":"102.13","中证800指数":"112.62"},
    {"日期":"2021/8/13","中信证券财富优选一年持有":"264.84","业绩基准":"101.41","中证800指数":"111.68"},
    {"日期":"2021/8/14","中信证券财富优选一年持有":"264.83","业绩基准":"101.41","中证800指数":"111.68"},
    {"日期":"2021/8/15","中信证券财富优选一年持有":"264.82","业绩基准":"101.41","中证800指数":"111.68"},
    {"日期":"2021/8/16","中信证券财富优选一年持有":"262.15","业绩基准":"101.09","中证800指数":"111.26"},
    {"日期":"2021/8/17","中信证券财富优选一年持有":"255.06","业绩基准":"97.67","中证800指数":"106.76"},
    {"日期":"2021/8/18","中信证券财富优选一年持有":"256.37","业绩基准":"99.37","中证800指数":"108.94"},
    {"日期":"2021/8/19","中信证券财富优选一年持有":"257.47","业绩基准":"98.63","中证800指数":"107.94"},
    {"日期":"2021/8/20","中信证券财富优选一年持有":"254.03","业绩基准":"96.17","中证800指数":"104.72"},
    {"日期":"2021/8/21","中信证券财富优选一年持有":"254.02","业绩基准":"96.17","中证800指数":"104.72"},
    {"日期":"2021/8/22","中信证券财富优选一年持有":"254.01","业绩基准":"96.17","中证800指数":"104.72"},
    {"日期":"2021/8/23","中信证券财富优选一年持有":"260.86","业绩基准":"98.41","中证800指数":"107.64"},
    {"日期":"2021/8/24","中信证券财富优选一年持有":"265.33","业绩基准":"100.08","中证800指数":"109.82"},
    {"日期":"2021/8/25","中信证券财富优选一年持有":"266.76","业绩基准":"100.68","中证800指数":"110.58"},
    {"日期":"2021/8/31","中信证券财富优选一年持有":"264.12","业绩基准":"98.95","中证800指数":"108.31"},
    {"日期":"2021/10/14","中信证券财富优选一年持有":"254.13","业绩基准":"100.28","中证800指数":"110.04"},
    {"日期":"2021/10/15","中信证券财富优选一年持有":"257.78","业绩基准":"100.81","中证800指数":"110.76"},
    {"日期":"2021/11/15","中信证券财富优选一年持有":"255.67","业绩基准":"98.69","中证800指数":"108.64"},
    {"日期":"2021/1/28","中信证券财富优选一年持有":"254.85","业绩基准":"97.88","中证800指数":"107.82"}
    ],
      barChartOption: {
        grid: {
          left: "165px",
          right: "90px",
          top: "0",
          bottom: "0",
        },
        animationDuration: 3000,
        animationDurationUpdate: 1000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        xAxis: [
          {
            type: "value",
            position: "bottom",
            splitLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#999",
                fontSize: 18,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: [],
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            axisLabel: {
              margin: 140,
              align: "left",
              textStyle: {
                fontSize: 31,
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            realtimeSort: true,
            type: "bar",
            data: [],
            barWidth: 38,
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(0,0,0,.1)",
              borderRadius: [0, 20, 20, 0],
            },
            label: {
              show: true,
              formatter: "{c}%",
              fontSize: 23,
              color: "#FF6A58",
              position: "right",
              margin: 23,
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data >= 0) {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        { offset: 0, color: "#FF6A58" },
                        { offset: 1, color: "#E13131" },
                      ],
                    };
                  } else {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        { offset: 0, color: "#136949" },
                        { offset: 1, color: "#13AE88" },
                      ],
                    };
                  }
                },
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      await sleep(1000);
      this.currentPage = 2;
      await sleep(100);
      await this.page2Fn();
      await sleep(1000);
      this.currentPage = 3;
      await sleep(100);
      await this.page3Fn();
      await sleep(1000);
      this.currentPage = 4;
      await sleep(100);
      await this.page4Fn();
      await sleep(1000);
      this.currentPage = 5;
      await sleep(100);
      await this.page5Fn();
      await sleep(1000);
       this.currentPage = 6;
      await sleep(100);
      await this.page6Fn();
      await sleep(1000);
      this.currentPage = 7;
      await sleep(100);
      await this.page7Fn();
      await sleep(1000);
      this.currentPage = 8;
      await sleep(100);
      await this.page8Fn();
      await sleep(1000);
      this.currentPage = 9;
      await sleep(100);
      await this.page9Fn();
      await sleep(1000);
      this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      $(".centerTxt").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.$refs.news2.playIn();
      await sleep(2500);
      // $(".card1").addClass("animate__animated animate__fadeout ");
    },
    // 第2页
    async page2Fn() {
      this.$refs.redTxt.playIn();
      await sleep(4000);
      // $(".card2").addClass("animate__animated animate__fadeout ");
    },
    // 第3页
    async page3Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".desc").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".desc .red").addClass("animate__animated animate__flash ");
      await sleep(1000);
      this.list1 = this.temList1;
      this.showList(1);
      await sleep(1000);
      $(".dashLine").addClass("animate__animated animate__fadeIn ");
      this.list2 = this.temList2;
      this.showList(2);
      await sleep(4000);
    },
    // 第4页
    async page4Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.loadMixChart();
      await sleep(8000);
    },
    // 第5页
    async page5Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.showBubble(1);
      await sleep(2000);
      this.showBubble(2);
      await sleep(3000);
    },
    async page6Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".dateTime").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.$refs["refDonutChart1"].DonutChart(this.population2, {
        name: (d) => d.name,
        value: (d) => d.value,
        colors: this.pieColorList,
        width: 480,
        height: 480,
        donutRatio: 0.6, //donut的内圆半径占比
        padAngle: 1, //pad间隔角度
        cornerRadius: 0, //圆角
        duration: 500,
        delay: 50,
        ease: "easeBackOut",
        labelPosition: "inside",
      });
      await sleep(500);
      $('.circleMiddle').css('opacity',1)
      this.loopNum4=10.70
       await sleep(500);
      this.listTemp = this.population2;
      this.showList(1, 200);
      await sleep(4000);
    },
    async page7Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".smallTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.loadChart(1);
      await sleep(3000);
      $(".dashLine").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".rightNum").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
    },
    async page8Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".smallTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.loadChart(2);
      await sleep(3000);
      $(".dashLine").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".rightNum").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
    },
    async page9Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".smallTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.loadChart(3);
      await sleep(3000);
      $(".dashLine").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".bottomWords").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
    },
    async showBubble(idx) {
      $(".bubble" + idx).addClass("animate__animated animate__bounceIn ");
      await sleep(500);
      $(".bubble" + idx + " .inner .tittle").addClass(
        "animate__animated animate__fadeIn "
      );
      if (idx == 1) {
        this.loopNum1 = -7.71;
      }
      await sleep(500);
      $(".bubble" + idx + " .inner .desc").addClass(
        "animate__animated animate__fadeIn "
      );
      if (idx == 1) {
        this.loopNum2 = -18.02;
      }
      if (idx == 2) {
        this.loopNum3 = 2685;
      }
      await sleep(500);
    },
    showList(idx, timeRange = 100) {
      const childClassName = ".column" + idx + " li";
      let liIdx = 0;
      let timer;
      timer = setInterval(function () {
        if (liIdx >= $(childClassName).length) {
          clearInterval(timer);
          timer = null;
        } else {
          $(childClassName).eq(liIdx).css({ opacity: 1 });
          $(childClassName)
            .eq(liIdx)
            .addClass("animate__animated animate__fadeIn");
          liIdx++;
        }
      }, timeRange);
    },
    loadMixChart() {
    let x=[], arr1=[],arr2=[],arr3=[]
      this.mixChartData.map(item=>{
        x.push(item['日期'])
        arr1.push(item['中证800指数'])
        arr2.push(item['中信证券财富优选一年持有'])
        arr3.push(item['业绩基准'])
      })
      this.myChart = this.$echarts.init(this.$refs.mixChart);
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["中证800指数", "中信财富优选一年持有", "业绩基数"],
          bottom: 0,
          icon: "circle",
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          itemGap: 20,
        },
        grid: {
          left: "2%",
          right: "0",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          show: false,
          boundaryGap: false,
          data: x,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#747C92",
            fontSize: "20",
            padding: 10,
          },
          splitLine: {
            lineStyle: {
              color: "#747C92",
            },
          },
        },
        color: ["#FF3B79", "#F7B500", "#B86BFF"],
        series: [
          {
            name: "中证800指数",
            type: "line",
            stack: "Total",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 4,
            },
            areaStyle: {},
            data: arr1,
          },
          {
            name: "中信财富优选一年持有",
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 4,
            },
            stack: "Total",
            data: arr2,
          },
          {
            name: "业绩基数",
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              type: "dotted",
              width: 4,
            },
            stack: "Total",
            data: arr3,
          },
        ],
      };
      this.myChart.setOption(option);
    },
     loadChart(idx) {
       console.log("loadChart..")
       let refName='chart'+idx
       let data=this.chartData['market'+idx]
      this.$refs[refName].BarChart(data, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: 680,
        height: 800,
        marginLeft: 225,
        marginRight: 80,
        duration: 200,
        delay: 200,
        yPadding: 0.5,
         isShowNumber: true,
        ease: "easeExp",
        numberSuffix: idx==2?"":"%",
        numberPosition: "outside"
      });
    }
  },
};
</script>

<style scoped lang='less'>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.main {
  background: url(../../assets/images/FOF/bg.png) no-repeat;
  width: 720px;
  height: 1280px;
}
.card1 {
  padding-top: 385px;
  .centerTxt {
    width: 528px;
    height: 374px;
    margin: 0 auto;
    color: #fff;
    font-size: 30px;
    padding: 76px 58px 52px;
    line-height: 1.8em;
    background: url(../../assets/images/FOF/rectBg.png) no-repeat;
    opacity: 0;
    .anime_txt {
      font-size: 30px;
    }
  }
}
.animate__animated {
  animation-fill-mode: both;
}
.card2 {
  padding-top: 466px;
  .redWords {
    width: 550px;
    margin: 0 auto;
    text-align: center;
    font-size: 80px;
    color: #ff6a58;
    font-weight: bold;
    .anime_txt {
      font-size: 80px;
    }
  }
}
.toptittle {
  font-size: 34px;
  color: #fff;
  opacity: 0;
}
.card3 {
  padding: 97px 38px;
  .desc {
    color: #747c92;
    font-size: 26px;
    margin-top: 28px;
    opacity: 0;
    span {
      margin-right: 5px;
    }
    .red {
      color: #ff6a58;
    }
  }
  .column {
    margin-bottom: 84px;
    li {
      opacity: 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
      .left {
        color: #fff;
      }
      .right {
        font-size: 26px;
        color: #747c92;
      }
    }
  }
  .column1 {
    margin-top: 104px;
    li {
      .left {
        font-size: 46px;
      }
      .green {
        color: #08c491;
      }
    }
  }
  .column2 {
    margin-top: 84px;
    li {
      .left {
        font-size: 30px;
      }
    }
  }
}

.dashLine {
  position: absolute;
  opacity: 0;
  top: 647px;
  width: 643px;
  left: 38px;
  border-bottom: dashed 2px #555971;
}

.card4 {
  padding: 97px 38px;
  .chart {
    margin-top: 40px;
    height: 690px;
  }
}
.card5 {
  padding: 98px 38px;
}
.bubble {
  background: url(../../assets/images/FOF/circleBg.png) no-repeat;
  background-size: 100%;
  width: 312px;
  height: 312px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  .inner {
    text-align: center;
  }
  .countWrap {
    display: inline-block;
    text-align: center;
  }
  .tittle {
    color: #fff;
    font-size: 42px;
    opacity: 0;
  }
  .desc {
    font-size: 24px;
    color: #747c92;
    margin-top: 15px;
    opacity: 0;
  }
  .desc2 {
    font-size: 26px;
  }
}
.bubble1 {
  left: 134px;
  top: 240px;
}
.bubble2 {
  left: 250px;
  top: 604px;
  width: 290px;
  height: 290px;
}
.card6 {
  padding: 98px 38px;
  .dateTime {
    color: #747c92;
    font-size: 24px;
    margin-top: 10px;
    opacity: 0;
  }
  .pieWrap {
    position: absolute;
    top: 324px;
    left: 120px;
    /deep/ text{
      display: none;
    }
  }
  .circleMiddle{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    color:#fff;
    text-align: center;
    width: 288px;
    transform: translate(-50%,-50%);
    opacity: 0;
    .count{
      font-size: 32px;
    }

  }
  .column {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 860px;
    left: 38px;
    width: 620px;
    li {
      width: 50%;
      padding: 0 20px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 26px;
      opacity: 0;
    }
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 5px;
      display: inline-block;
    }
  }
}
.card7,
.card8,
.card9 {
  padding: 98px 38px;
  .chart {
    width: 680px;
    height: 800px;
    position: absolute;
    top: 210px;
    left: 20px;
  }
  .dashLine {
    border-color: #747c92;
    top: 1070px;
  }
  .bottomWords,
  .rightNum {
    opacity: 0;
    position: absolute;
    top: 1085px;
    right: 35px;
    background: url(../../assets/images/FOF/sayUp1.png);
    width: 264px;
    height: 58px;
    line-height: 62px;
    text-align: center;
  }
  .bottomWords {
    color: #ff6a58;
    font-size: 31px;
    background-image: url(../../assets/images/FOF/sayUp2.png);
    width: 590px;
  }
  .rightNum {
    color: #ff6a58;
    font-size: 25px;
  }
}
.card7 {
  .smallTittle {
    color: #747c92;
    font-size: 23px;
    margin-top: 10px;
    opacity: 0;
  }
}
.c_bar3 {
  font-size: 23px;
  min-height: 200px;
  margin-bottom: 20px;
  /deep/ .numbers {
    font-size: 23px;
    fill: #fff;
  }
  /deep/ .y_label {
    font-size: 23px;
    fill: #953e23;
  }
  /deep/ .axis_x {
    font-size: 23px;
    .tick_line,
    .domain {
      display: none;
    }
    .text {
      fill: #fdb462;
    }
  }
  /deep/ .axis_y {
    font-size: 27px;
    .domain {
      display: none;
    }
    .tick_line {
      stroke: #ffffff;
    }
    .text {
      fill: #ffffff;
    }
    .tick_long_line {
      stroke:rgba(141,211,199,.15)
    }
  }
  /deep/ .bar_group .bar_positive {
    fill: url(#barPositive);
  }
  /deep/ .bar_group .bar_negative {
    fill: url(#barNegative);
  }
    /deep/ .numbers_positive {
    fill: #FF6A58;
  }
  /deep/ .numbers_negative {
    fill: #20C687;
  }

}
.svg_gradient {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: -1;
}

</style>