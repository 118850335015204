<template>
  <div class="width-100 height-100 max_box">
    <component
      :is="currentView"
      :cardData="cardData"
      ref="coms"
      @playEnd="playNext()"
    ></component>
  </div>
</template>
<script>
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import OneComponent from "./one.vue";
import TwoComponent from "./two.vue";
export default {
  name: "guojun",
  components: {
    OneComponent,
    TwoComponent,
  },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      currentIndex: 1,
      aduioData: [], //旁白的数据
      cardData: {},
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    currentView() {
      if (this.currentIndex == 1) {
        return OneComponent;
      }
      if (this.currentIndex == 2) {
        return TwoComponent;
      }

      return OneComponent;
    },
  },
  async mounted() {
    if (this.ip && this.port) {
      console.log(666);
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
           this.load();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
     
    } else {
      // alert("没获取到ip,port");
      this.load();
    }
  },
  methods: {
    endRecord() {
      this.aduioData = [
        //p1 4s
        // p2
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-46343590.mp3",
          timeIndex: 4,
          audioLen: 5,
        },
        // p3
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-214d133b.mp3",
          timeIndex: 9,
          audioLen: 9,
        },
         // p4
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-0d7249f5.mp3",
          timeIndex: 18,
          audioLen: 10,
        },
         // p5
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-dfc4690f.mp3",
          timeIndex: 28,
          audioLen: 7,
        },
         // p6
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-b334f41e.mp3",
          timeIndex: 35,
          audioLen: 8,
        },
         // p7,p8
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-383cee47.mp3",
          timeIndex: 43,
          audioLen: 15,
        },
         // p9
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-40d12de4.mp3",
          timeIndex: 60,
          audioLen: 7,
        },
         // p10,p11
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-60c51e4d.mp3",
          timeIndex: 67,
          audioLen: 11,
        },
         // p12,p14
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-ceb459d1.mp3",
          timeIndex: 78,
          audioLen: 11
        },
      ];
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 89,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: this.aduioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    // 页面card一个接一个播放
    async load() {
      // this.$refs.bgAudio.volume = 0.1;
      // this.$refs.bgAudio.play();
      this.$refs.coms.playCardStart();

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 89*1000);
      }
    },
    playNext() {
      this.currentIndex++;
      this.$nextTick(() => {
        this.$refs.coms.playCardStart();
      });
    },
  },
};
</script>
<style scoped lang="less">
</style>