<template>
  <div class="main">
    <!-- 第10页 -->
    <div key="card10" class="card10" v-if="currentPage == 10">
      <div class="redWords">
        <anime-txt
          id="redTxt"
          ref="redTxt"
          class="anime_txt"
          txt="产品管理人"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="topIn"
          outEffect="fadeOut"
        />
      </div>
    </div>
    <!-- 第11页 -->
    <div key="card11" class="card11" v-if="currentPage == 11">
      <div class="toptittle">中信证券财富优选一年持有FOF投资经理</div>
      <div class="personRow clearfix">
        <div class="imgWrap">
          <img class="person" src="../../assets/images/FOF/person.png" />
          <img class="good" src="../../assets/images/FOF/good.png" />
        </div>
        <div class="desc">
          <div class="name">陈晓非</div>
          <div class="secName">投资经理</div>
          <ul class="column column1">
            <li v-for="(item, index) in list1" :key="index">
              <div>
                <div v-html="item.label"></div>
                <template v-if="item.isCount">
                  <countTo
                    :startVal="0"
                    :endVal="item.value"
                    :decimals="0"
                    :duration="2000"
                  ></countTo>
                </template>
                <template v-else>
                  <span> {{ item.value }}</span>
                </template>
                <span>{{ item.unit }} </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottomRow">
        <div class="left">
          <div>现任基金资产 <br> 总规模</div>
          <div class="redWords">
            <countTo
              :startVal="0"
              :endVal="count1"
              :decimals="1"
              :duration="2000"
            ></countTo>
            亿元
          </div>
        </div>
        <div>
          <div>任职期间最佳基金 <br> 回报率</div>
          <div class="redWords">
            <countTo
              :startVal="0"
              :endVal="count2"
              :decimals="2"
              :duration="2000"
            ></countTo>
            %
          </div>
        </div>
      </div>
    </div>
    <!-- 第12页 -->
    <div key="card12" class="card12" v-if="currentPage == 12">
       <div class="toptittle">业绩评价</div>
        <div class="chart" ref="spiderChart"></div>
    </div>
    <!-- 第13页 -->
    <!-- <div key="card13" class="card13" v-if="currentPage == 13"></div> -->
    <!-- 第14页 -->
    <div key="card14" class="card14" v-if="currentPage == 14">
         <div class="toptittle">扫描二维码进行下载</div>
         <div class="code"> 
            <img class="QRCode" src="../../assets/images/FOF/QRCode.png">
            <div class="tips"> 扫一扫了解更多信息 </div>
         </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import animeTxt from "@/components/animeTxt/index.vue";
import countTo from "vue-count-to";
import { sleep } from "@/utils/common";
export default {
  components: {
    animeTxt,
    countTo,
  },
  data: function () {
    return {
      currentPage: 10,
      list1: [],
      list1Temp: [
        {
          label: "累计任职时间",
          value: 200,
          isCount: true,
          unit: "天",
        },
        {
          label: "任职起始日期",
          value: "2021-07-22",
        },
        {
          label: "任职基金公司",
          value: "中信证券股份有限公司",
        },
      ],
      count1: undefined,
      count2: undefined,
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      await this.page10Fn();
      await sleep(1000);
      this.currentPage = 11;
      await sleep(100);
      await this.page11Fn();
      await sleep(1000);
      this.currentPage = 12;
      await sleep(100);
      await this.page12Fn();
      await sleep(1000);
      this.currentPage = 14;
      await sleep(100);
      await this.page14Fn();
      await sleep(1000);
    },
    // 第10页
    async page10Fn() {
      console.log("page10Fn...");
      this.$refs.redTxt.playIn();
    },
    // 第11页
    async page11Fn() {
      $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".person").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".name").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".secName").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      this.list1 = this.list1Temp;
      this.showList(1);
      await sleep(2000);
      $(".good").css({ opacity: 1 });
        $(".good").addClass("bigIn");
      await sleep(1000);
      this.count1 = 0.70;
      this.count2 = 8.01;
      $(".bottomRow").addClass("animate__animated animate__fadeIn ");
      await sleep(2000);
    },
    // 第12页
    async page12Fn() {
       $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
          this.loadSpiderChart();
      await sleep(5000);
    },
    // 第14页
    async page14Fn() {
       $(".toptittle").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
       $(".QRCode").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
       $(".tips").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
    },
      //业绩评价雷达图
    loadSpiderChart() {
      var myChart = this.$echarts.init(this.$refs.spiderChart);
      var option = {
        radar: [
          {
            name: {
              textStyle: {
                color: "#fff",
                borderRadius: 3,
                fontSize: 22,
                fontWeight: 500,
              },
            },
            indicator: [
              {
                name: "选证能力",
                max: 6500,
                axisLabel: { show: true, fontSize: 22 ,color:'#747C92'},
              },
              { name: "择时能力", max: 16000 },
              { name: "稳定性", max: 30000 },
              { name: "抗风险", max: 38000 },
              { name: "收益率", max: 52000 },
            ],
            center: ["52%", "50%"],
            nameGap: 10,
            axisTick: {
              show: true,
              lineStyle: {
                color: "#FF6A58",
              },
            },
            axisLine: {
              // 坐标轴轴线相关设置。
              lineStyle: {
                color: "#FF6A58",
                width: 1.8,
              },
            },

            splitLine: {
              // 坐标轴在 grid 区域中的分隔线
              lineStyle: {
                color: "#FF6A58",
                width: 1.8,
              },
            },
            splitArea: {
              // 坐标轴在 grid 区域中的分隔区域 (背景色)
              show: true,
            },
          },
        ],
        animationDuration: 2000,
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: [
              {
                value: [5000, 14000, 28000, 26000, 42000, 21000],
                name: "Actual Spending",
                symbol: "none",
                lineStyle: {
                  color: "#FF8F82",
                  width: 3,
                },
                areaStyle: {
                  opacity: 0.25,
                  color: "#FF8F82",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option, true);
    },
    showList(idx, timeRange = 100) {
      const childClassName = ".column" + idx + " li";
      let liIdx = 0;
      let timer;
      timer = setInterval(function () {
        if (liIdx >= $(childClassName).length) {
          clearInterval(timer);
          timer = null;
        } else {
          $(childClassName).eq(liIdx).css({ opacity: 1 });
          $(childClassName)
            .eq(liIdx)
            .addClass("animate__animated animate__fadeIn");
          liIdx++;
        }
      }, timeRange);
    },
    // 龙虎榜-营业部净买入个股
    loadChart(idx) {
      // 基于准备好的dom，初始化this.$echarts实例
      const className = "chart" + idx;
      const classBgName = "chart" + idx + "Bg";
      this.myChart = this.$echarts.init(document.getElementById(className));
      // 指定图表的配置项和数据
      var option = this.refreshOption(idx);
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    },
    refreshOption(idx) {
      var option = _.cloneDeep(this.barChartOption);
      const tempData = this.cardData;
      const label = "产品_" + idx;
      const value = "涨跌幅_" + idx;
      option.yAxis[0].data = tempData[label];
      option.series[0].data = tempData[value];
      return option;
    },
  },
};
</script>

<style scoped lang='less'>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.main {
  background: url(../../assets/images/FOF/bg.png) no-repeat;
  width: 720px;
  height: 1280px;
}
.card10 {
  padding-top: 466px;
  .redWords {
    text-align: center;
    font-size: 80px;
    color: #ff6a58;
    font-weight: bold;
    .anime_txt {
      font-size: 80px;
    }
  }
}
.toptittle {
  font-size: 34px;
  color: #fff;
  opacity: 0;
}

.card11 {
    padding: 98px 38px;
  color: #fff;
  .personRow {
    margin-top:100px;
    .desc {
      padding: 15px 15px 15px 20px;
         margin-left: 417px;
      .name {
        font-size: 46px;
        opacity: 0;
      }
      .secName {
        font-size: 26px;
        color: #747c92;
        margin-top: 10px;
        opacity: 0;
      }
    }
  }
  .imgWrap {
    position: relative;
    float: left;
    .person {
      width: 417px;
      height: 621px;
      opacity: 0;
    }
    .good {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 65px;
      height: 68px;
      opacity: 0;
    }
  }
   .bigIn{
    animation: bigInAnimate 0.5s;
  }
  @keyframes bigInAnimate {
  0% {
      transform-origin: 50% 50%;
    transform: scale(5);
    transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    transform: scale(1);
  }
}
  .column li {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    margin-top: 58px;
    background: url(../../assets/images/FOF/sayBg1.png) no-repeat;
    height: 91px;
    width: 179px;
    opacity: 0;
  }
  .column li:nth-child(2) {
    width: 180px;
    background-image: url(../../assets/images/FOF/sayBg2.png);
  }
  .column li:nth-child(3) {
    background-image: url(../../assets/images/FOF/sayBg3.png);
    width: 215px;
    font-size: 20px;
  }
  .bottomRow {
    display: flex;
    font-size: 30px;
    margin-top: 57px;
    opacity: 0;
    &>div{
      padding: 15px 25px;
      text-align: center;
      flex:1;
    }
    .left {
      border-right: dashed 3px #747c92;
      text-align: center;
    }
    .redWords {
      color: #ff6a58;
      font-size: 46px;
      margin-top:5px;
    }
  }
}
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.card12{
  padding: 98px 38px;
  .chart{
    width:645px;
    height: 720px;
    margin-top:160px;
  }
}
.card14{
   padding: 98px 38px;
   .code{
     margin-top:162px;
     text-align: center;
   }
   .QRCode{
     width:464px;
     height: 464px;
     opacity: 0;
     display: inline-block;
   }
   .tips{
     margin-top:34px;
     text-align: center;
     font-size: 31px;
     color:#fff;
     opacity: 0;
   }
}
</style>